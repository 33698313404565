import React from "react";
import styled from "styled-components";
import {
  select,
  scaleLinear,
  max,
  scaleBand,
  stack,
  stackOrderNone,
  stackOffsetNone,
  axisBottom,
  axisLeft
} from "d3";
import { toneCount as data } from "./data/toneCount";

const colours = {
  joy: "#065d74",
  tentative: "#64a2c2",
  fear: "#dbdfde",
  analytical: "#dab47c",
  anger: "#d2873a",
  confident: "#942d28",
  sadness: "#775039"
};

const margin = { top: 12, right: 20, bottom: 10, left: 60 },
  width = 490 - margin.left - margin.right,
  height = 400 - margin.top - margin.bottom;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
`;

const Text = styled.p`
  color: #909090;
  padding: 0;
  margin: 0;
  font-size: 16px;
`;

export default () => {
  const ref = React.useRef(null);

  const dataKeys = Object.keys(data);

  const getLineCountForCharacter = character => {
    let result = 0;
    Object.keys(data[character]).forEach(toneCategory => {
      result += data[character][toneCategory];
    });
    return result;
  };

  const moreStackData = dataKeys.map(char => ({
    character: char,
    ...data[char]
  }));

  const ourStack = stack()
    .keys([
      "joy",
      "tentative",
      "fear",
      "analytical",
      "anger",
      "confident",
      "sadness"
    ])
    .order(stackOrderNone)
    .offset(stackOffsetNone);

  React.useEffect(() => {
    const xScale = scaleBand()
      .range([margin.left, width])
      .padding(0.2)
      .domain(dataKeys);

    const yScale = scaleLinear()
      .domain([0, max(dataKeys, d => getLineCountForCharacter(d))])
      .range([height, 0]);

    const xAxis = g =>
      g
        .attr("transform", `translate(0,${height + 10})`)
        .call(
          axisBottom(xScale)
            .tickSizeOuter(0)
            .tickSize(10, 0, 0)
        )
        .call(g => g.selectAll(".domain").remove())
        .call(g => g.selectAll("line").attr("stroke", "grey"));

    const yAxis = g =>
      g
        .attr("transform", `translate(${margin.left - 5},0)`)
        .call(
          axisLeft(yScale)
            .ticks(6, "s")
            .tickSize(10, 0, 0)
        )
        .call(g => g.selectAll(".domain").remove())
        .call(g => g.selectAll("line").attr("stroke", "grey"));

    select(ref.current)
      .selectAll("g")
      .data(ourStack(moreStackData))
      .join("g")
      .attr("fill", d => colours[d.key])
      .selectAll("rect")
      .data(d => d)
      .join("rect")
      .attr("x", (d, i) => xScale(d.data.character))
      .attr("y", d => yScale(d[1]))
      .attr("height", d => yScale(d[0]) - yScale(d[1]))
      .attr("width", xScale.bandwidth());

    select(ref.current)
      .append("g")
      .call(xAxis);

    select(ref.current)
      .append("g")
      .call(yAxis);

    var legend = select(ref.current)
      .selectAll(".legend")
      .data(Object.keys(colours))
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr("transform", function(d, i) {
        return "translate(30," + i * 19 + ")";
      });

    legend
      .append("rect")
      .attr("x", width - 135)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", d => colours[d]);

    legend
      .append("text")
      .attr("x", width - 110)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "start")
      .text(d => d);

    select(ref.current)
      .append("text")
      .attr("x", 265)
      .attr("y", height + 60)
      .style("text-anchor", "end")
      .text("Character");

    select(ref.current)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .text("Line Count");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <Text>Break down of line sentiment by character</Text>
      <svg
        width={width}
        height={500}
        ref={ref}
        viewBox={[0, 0, width, height]}
      />
    </Container>
  );
};
