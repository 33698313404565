import React from 'react';
import styled from 'styled-components';
import { select, scaleLinear, max, forceX, forceY, forceSimulation, forceManyBody, forceCollide } from 'd3';

const height = 175;

const width = 400;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const colours = {
  gandalf: '#d2873a',
  aragorn: '#5d993b',
  boromir: '#942d28',
  gimli: '#586061',
  frodo: '#64a2c2',
  sam: '#dab47c',
  merry: '#065d74',
  pippin: '#dbdfde',
  legolas: '#775039',
};

export default ({ name, lines, hoveredLineCallback }) => {
  const ref = React.useRef(null);

  var rScale = scaleLinear()
    .domain([0, max(lines, d => d.line.split(' ').length)])
    .range([3, 25]);

  var xScale = scaleLinear()
    .domain([0, lines.length])
    .range([30, width - 30]);

  const data = lines
    .map(({ line, char }, i) => ({
      index: i,
      line,
      char,
      radius: rScale(line.split(' ').length),
    }))
    .sort(() => Math.random() - Math.random());

  React.useEffect(() => {
    function ticked() {
      const node = select(ref.current)
        .selectAll('circle')
        .data(data);

      node
        .enter()
        .append('circle')
        .attr('r', 5)
        .merge(node)
        .attr('fill', d => colours[d.char.toLowerCase()])
        .attr('opacity', 1)
        // .attr('stroke', 'black')
        .attr('r', d => d.radius)
        .attr('cx', function(d) {
          return d.x;
        })
        .attr('cy', function(d) {
          return d.y;
        })
        .on('mouseover', function(d, i) {
          hoveredLineCallback(d.line, d.char);
          select(this).style('opacity', 0.7);
        })
        .on('mouseout', function(d, i) {
          hoveredLineCallback('');
          select(this).style('opacity', 1);
        });
      node.exit().remove();
    }

    forceSimulation(data)
      .force('charge', forceManyBody().strength(5))
      .force(
        'x',
        forceX().x(function(d, i) {
          return xScale(i);
        })
      )
      .force(
        'y',
        forceY().y(function(d) {
          return height / 2;
        })
      )
      .force('collision', forceCollide().radius(d => d.radius + 1))
      .on('tick', ticked);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container>
      <svg width={width} height={height} ref={ref} />
    </Container>
  );
};
