import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import App from './App';
import aniron from './fonts/aniron.ttf';

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face { 
    font-family: 'Aniron';
    src: local('Aniron'),
    url(${aniron}) format('truetype');
  }
  body {
    margin: 0;
    padding: 0;

    @media (max-width: 600px) {
      padding: 0 16px;
    }
  }
`;

const rootElement = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Speak friend and enter</title>
      <meta name="description" content="Visualising the data of Lord of the Rings" />
    </Helmet>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  rootElement
);
