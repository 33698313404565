import styled from 'styled-components';

export const Title = styled.h1`
  font-family: Aniron, serif;
  size: 32px;
  max-width: 600px;
  width: 100%;
  text-align: center;
`;

export const Subtitle = styled.h2`
  font-family: Aniron, serif;
  size: 22px;
  text-align: left;
  max-width: 500px;
  margin-top: 32px;
  width: 100%;
`;

export const Body = styled.p`
  font-family: serif;
  font-size: 18px;
  font-weight: ${p => p.strong && 'bold'};
  margin: 0;
  padding: 0;
  text-align: left;
  max-width: 500px;
  width: 100%;
  margin-top: ${p => (p.marginTop ? p.marginTop : 0)};
`;
