export const toneCount = {
  gandalf: {
    sadness: 38,
    analytical: 110,
    confident: 57,
    // neutral: 307,
    joy: 71,
    tentative: 64,
    fear: 28,
    anger: 17
  },
  frodo: {
    // neutral: 259,
    sadness: 34,
    analytical: 57,
    tentative: 37,
    joy: 31,
    confident: 26,
    anger: 9,
    fear: 7
  },
  sam: {
    analytical: 67,
    tentative: 66,
    // neutral: 281,
    joy: 30,
    sadness: 38,
    fear: 23,
    confident: 25,
    anger: 18
  },
  merry: {
    // neutral: 129,
    tentative: 37,
    fear: 6,
    joy: 27,
    sadness: 14,
    confident: 15,
    analytical: 27,
    anger: 5
  },
  pippin: {
    // neutral: 143,
    analytical: 31,
    sadness: 21,
    fear: 11,
    tentative: 33,
    joy: 49,
    confident: 10,
    anger: 8
  },
  aragorn: {
    joy: 28,
    // neutral: 257,
    sadness: 25,
    analytical: 34,
    confident: 20,
    tentative: 20,
    fear: 12,
    anger: 7
  },
  gimli: {
    analytical: 34,
    confident: 13,
    // neutral: 108,
    tentative: 22,
    joy: 20,
    sadness: 12,
    fear: 11,
    anger: 7
  },
  legolas: {
    // neutral: 55,
    anger: 9,
    confident: 5,
    fear: 4,
    joy: 7,
    tentative: 10,
    analytical: 9,
    sadness: 7
  },
  boromir: {
    sadness: 12,
    joy: 13,
    analytical: 9,
    // neutral: 40,
    confident: 7,
    anger: 6,
    tentative: 3,
    fear: 3
  }
};
