import React from 'react';
import styled from 'styled-components';
import LineLength from './combinedLineLength';
import { ringMentions } from './data/secondRingMentions';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  height: 300px;
  padding: 0 8px;
  margin-top: 32px;
`;

const Name = styled.p`
  padding: 0;
  margin: 0;
  text-align: right;
  width: 100%;
`;

const Quote = styled.p`
  padding: 0;
  margin: 0;
  width: 400px;
  font-size: 18px;
`;

export default () => {
  const ref = React.useRef(null);
  const [hoveredLine, setHoveredLine] = React.useState({});

  const handleHover = (line, char) => {
    setHoveredLine({ char, line });
  };

  return (
    <Container ref={ref}>
      <LineLength name="gandalf" lines={ringMentions} hoveredLineCallback={handleHover} />
      <Quote>{hoveredLine.line && `"${hoveredLine.line}"`}</Quote>
      <Name>{hoveredLine.char && `- ${hoveredLine.char}`}</Name>
    </Container>
  );
};
