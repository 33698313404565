export const ringMentions = [
  { line: 'You cannot give me this', char: 'Aragorn' },
  { line: 'You cannot wield it.', char: 'Aragorn' },
  { line: 'None of us can The One Ring answers to Sauron alone.', char: 'Aragorn' },
  { line: 'Boromir! Give the Ring to Frodo', char: 'Aragorn' },
  { line: 'If Sauron had the Ring we would know it.', char: 'Aragorn' },
  {
    line:
      'At all times they feel the presence of the Ring Drawn to the power of the One They will never stop hunting you.',
    char: 'Aragorn',
  },
  { line: 'A little more caution from you! That is no trinket you carry!', char: 'Aragorn' },
  { line: 'Where is the ring?', char: 'Aragorn' },
  { line: 'The ring is beyond our reach now.', char: 'Aragorn' },
  {
    line:
      "The shards of Narsil The blade that cut the Ring from Sauron's hand Ah! Its still sharp but no more than a broken heirloom",
    char: 'Boromir',
  },
  { line: 'Aye it is a gift! A gift to the foes of Mordor.', char: 'Boromir' },
  {
    line: 'Why not use this Ring? Long has my father the Steward of Gondor kept the forces of Mordor at bey.',
    char: 'Boromir',
  },
  { line: 'The One Ring.', char: 'Boromir' },
  { line: 'I ask only for the strength to defend my people. If you would but lend me the ring...', char: 'Boromir' },
  {
    line:
      'What chance do you think you have? They will find you, they will take the ring and you will beg for death before the end.',
    char: 'Boromir',
  },
  {
    line:
      'You fool! It is not yours save by unhappy chance...it might have been mine. It should be mine. Give it to me! Give me the ring.',
    char: 'Boromir',
  },
  { line: 'He spends hours and hours poring over old maps when he thinks Im not looking.', char: 'Frodo' },
  { line: 'and the Ring will be safe there ?', char: 'Frodo' },
  { line: "You're right Sam We did what we set out to do the Ring will be safe in Rivendell.", char: 'Frodo' },
  { line: 'I will take it I will take it I will take the Ring to Mordor Tho I do not know the way', char: 'Frodo' },
  { line: 'I wish the Ring had never come to me.', char: 'Frodo' },
  { line: 'The Ring is treacherous.', char: 'Frodo' },
  { line: "You have no idea what it did to him......what it's still doing to him.", char: 'Frodo' },
  { line: 'The Ring was entrusted to me.', char: 'Frodo' },
  { line: "The Ring's taking me Sam.", char: 'Frodo' },
  { line: 'The Ring will not save Gondor.', char: 'Frodo' },
  { line: "It's calling to him Sam.", char: 'Frodo' },
  { line: 'Theyve taken it Sam they took the Ring!', char: 'Frodo' },
  { line: 'Give it to me! Give me the Ring Sam.', char: 'Frodo' },
  { line: 'Sam! Give me the Ring.', char: 'Frodo' },
  { line: 'The Ring is my burden.', char: 'Frodo' },
  { line: 'It will destroy you Sam.', char: 'Frodo' },
  { line: 'I cant! I cant I cant manage the Ring Sam.', char: 'Frodo' },
  { line: 'The Ring is mine!', char: 'Frodo' },
  { line: 'Im afraid I lost it.', char: 'Frodo' },
  { line: 'If you ask it of me, I will give you the One Ring.', char: 'Frodo' },
  { line: 'It has taken Boromir.', char: 'Frodo' },
  { line: 'Would you destroy it?', char: 'Frodo' },
  { line: 'What about this ring of yours ? Is that staying too ?', char: 'Gandalf' },
  { line: 'I think you should leave the ring behind Bilbo.', char: 'Gandalf' },
  { line: 'Bilbo! The ring is still in your pocket.', char: 'Gandalf' },
  { line: "Bilbo's Ring.", char: 'Gandalf' },
  { line: 'Along with all his possessions The Ring is yours now Put it out of sight.', char: 'Gandalf' },
  {
    line:
      'It has come to me the One Ring and it shall be an heirloom of my Kingdom All those who follow in my bloodline shall be bound to its fate for I shall risk no hurt to the Ring.',
    char: 'Gandalf',
  },
  { line: "I think you've had that ring quite long enough.", char: 'Gandalf' },
  {
    line:
      'In the common tongue it saysOne Ring to Rule Them All One Ring to find them One Ring to bring them all and in the darkness bind them!',
    char: 'Gandalf',
  },
  { line: "Yes for sixty years the Ring lay quiet in Bilbo's keeping prolonging his life.", char: 'Gandalf' },
  { line: 'The Ring has awoken.', char: 'Gandalf' },
  { line: 'This is the One Ring.', char: 'Gandalf' },
  { line: 'There is one other who knew Bilbo had the Ring.', char: 'Gandalf' },
  { line: 'His life force is bound to the Ring and the Ring survived.', char: 'Gandalf' },
  { line: 'Sauron needs only this Ring to cover all the lands of a second darkness.', char: 'Gandalf' },
  { line: 'The Ring yearns above all else to return to the hand of its master.', char: 'Gandalf' },
  { line: 'They are one the Ring and the Dark Lord.', char: 'Gandalf' },
  { line: 'You cannot offer me this Ring', char: 'Gandalf' },
  { line: 'Understand Frodo I would use this Ring from a desire to do good.', char: 'Gandalf' },
  { line: 'Always remember Frodo the Ring is trying to get back to its Master.', char: 'Gandalf' },
  {
    line: 'There is only Lord of the Ring Only one who can bend it to his will and he does not share power',
    char: 'Gandalf',
  },
  { line: 'Saruman is coming for the Ring.', char: 'Gandalf' },
  { line: "and the Ring ? You feel its power growing don't you.", char: 'Gandalf' },
  {
    line: "Escaped or was set loose and now the Ring has drawn him here He won't ever be rid of his need for it.",
    char: 'Gandalf',
  },
  { line: 'He hates and loves the Ring as he hates and loves himself.', char: 'Gandalf' },
  {
    line:
      'Bilbo was meant to find the Ring in which case you also were meant to have it and that is an encouraging thought.',
    char: 'Gandalf',
  },
  { line: 'The Ring remains hidden.', char: 'Gandalf' },
  { line: 'What did you tell him about Frodo and the Ring?', char: 'Gandalf' },
  { line: 'He told Sauron nothing of Frodo and the Ring.', char: 'Gandalf' },
  { line: 'And do not mention Frodo or the Ring.', char: 'Gandalf' },
  { line: 'I will be dead before I see the Ring in the hands of an elf', char: 'Gimli' },
  { line: 'Have you heard nothing Lord Elrond has said? The Ring must be destroyed', char: 'Legolas' },
  { line: 'That black rider was looking for something.', char: 'Merry' },
  {
    line: "Don't you understand? The enemy thinks you have the ring! He is going to be looking for you Pip.",
    char: 'Merry',
  },
  { line: 'They think we have the Ring.', char: 'Pippin' },
  {
    line:
      "Nothing important That is I heard a good deal about a ring and a dark lord and something about the end of the world but please Mr Gandalf Sir please don't hurt me. Don't turn me into anything... unnatural",
    char: 'Sam',
  },
  {
    line: "Its just We did what Gandalf wanted didn't we ? We got the Ring this far to Rivendell and I thought...",
    char: 'Sam',
  },
  { line: "Frodo? It's the Ring isn't it?", char: 'Sam' },
  { line: "It's the Ring he wants.", char: 'Sam' },
  { line: "It's the Ring.", char: 'Sam' },
  { line: 'Use the Ring Mr. Frodo', char: 'Sam' },
  {
    line:
      'He tried to take the Ring from Frodo after swearing an oath to protect him! He tried to kill him! The Ring drove your brother mad!',
    char: 'Sam',
  },
  {
    line:
      'I wonder if people will ever say "Let\'s hear about Frodo and the Ring." And they\'ll say "Yes! That\'s one of my favourite stories."',
    char: 'Sam',
  },
  { line: 'So I took it only for safekeeping.', char: 'Sam' },
  {
    line:
      'Then let us be rid of it once and for all! Come on Mr Frodo I cant carry it for you but I can carry you! Come on!',
    char: 'Sam',
  },
];
