import React from 'react';
import styled from 'styled-components';
import { select, scaleLinear, max } from 'd3';

const width = '500';
const height = '40';
const colours = ['#5d993b', '#065d74', '#942d28'];

const data = [
  {
    name: 'The Fellowship of the Ring',
    dialogueCount: 9838,
  },
  {
    name: 'The Two Towers',
    dialogueCount: 12299,
  },
  {
    name: 'The Return of the King',
    dialogueCount: 10689,
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  height: 125px;
`;

const Text = styled.p`
  color: #909090;
  padding: 0;
  margin: 4px 0;
  font-size: ${p => (p.size ? p.size : '16px')};
`;

export default () => {
  const ref = React.useRef(null);
  const [currentMovie, setCurrentMovie] = React.useState('');

  var xScale = scaleLinear()
    .domain([0, max(data, d => d.dialogueCount)])
    .range([0, width / 3]);

  const getX = (arr, i) => {
    let res = 0;
    if (i < 1) {
      return res;
    }
    for (let j = i - 1; j >= 0; j--) {
      res += xScale(arr[j].dialogueCount);
    }
    return res;
  };

  function onMouseOver(d, i) {
    select(this)
      .attr('opacity', 0.9)
      .append('text')
      .text(d.dialogueCount)
      .attr('id', 'movietitle')
      .attr('fill', 'white')
      .attr('font-size', '16px')
      .attr('x', getX(data, i) + xScale(d.dialogueCount) / 2)
      .attr('y', height / 2)
      .attr('dominant-baseline', 'central')
      .attr('text-anchor', 'middle');

    setCurrentMovie(d.name);
  }

  function onMouseExit(d, i) {
    select(this).attr('opacity', 1);
    select('#movietitle').remove();
    setCurrentMovie('');
  }

  React.useEffect(() => {
    select(ref.current)
      .selectAll('rects')
      .data(data)
      .enter()
      .append('g')
      .on('mouseover', onMouseOver)
      .on('mouseout', onMouseExit)
      .append('rect')
      .attr('width', d => xScale(d.dialogueCount))
      .attr('height', height)
      .attr('x', (d, i) => getX(data, i))
      .attr('y', 0)
      .attr('fill', (d, i) => colours[i]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let svgWidth = 0;

  data.forEach(film => {
    svgWidth += xScale(film.dialogueCount);
  });

  return (
    <Container>
      <Text>25342 words</Text>
      <svg width={svgWidth} height={height} ref={ref} />
      <Text size="12px">(hover for breakdown)</Text>
      <Text>{currentMovie}</Text>
    </Container>
  );
};
