import React from "react";
import styled from "styled-components";
import DialogueCount from "./dialogueCount";
import CharacterTone from "./characterTone";
import AllLines from "./allLines";
import { Title, Body, Subtitle } from "./typography";
import Footer from "./footer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function App() {
  return (
    <Container>
      <Title>Speak friend and enter</Title>
      <Body marginTop="-16px">
        A quick analysis of the dialogue of The Lord of the Rings movies
      </Body>
      <Body marginTop="32px">
        I wanted to learn a new framework for visualising data and I love the
        Lord of the Rings movies. Here's what I found combining the two
        together.
      </Body>
      <Body marginTop="8px">
        Through a web scraping and pulling the data from the film scripts I
        processed the words of the rings in three ways.
      </Body>
      <Subtitle>Total Word Count</Subtitle>
      <Body>
        The first thing I looked at was the total word count per film. We can
        see below that The Two Towers has a real 'middle movie' vibe with the
        most words said to pull the three stories together.
      </Body>
      <DialogueCount />
      <Subtitle>Tonal Analysis</Subtitle>
      <Body>
        I utilised the brains of IBM's Watson to process each line said by our
        nine fellowship members. Any lines in which tone could be extracted I
        then rendered in this here graph.
      </Body>
      <CharacterTone />
      <Body>
        As you could expect, Gandalf says the most lines across all three films
        and his words convey the most emotion.
      </Body>
      <Body marginTop="8px">
        Surprisingly, Boromir says more in Fellowship of the Ring than Legolas
        does throughout all three films.
      </Body>
      <Body marginTop="8px">
        Unsurprisingly, Boromir's words primarily convey two emotions Sean Bean
        does well: Joy and Sadness.
      </Body>
      <Subtitle>Speaking of the Ring</Subtitle>
      <Body>
        I then spent some time pulling out each mention of the ring by our nine
        fellowship members.
      </Body>
      <Body marginTop="4px">
        Below we can see a render of this data where the size of the circle if
        the length of the line and the colour is the character speaking.
      </Body>
      <Body marginTop="16px">Hover over each line to see the quote below.</Body>
      <AllLines />
      <Subtitle>References</Subtitle>
      <Body>I could not have achieved this without the following:</Body>
      <Body
        as="a"
        href="https://www.amazon.co.uk/Lord-Rings-Trilogy-Blu-ray-Region/dp/B013E4OWPC/ref=sr_1_2?crid=2NYYEVTM641G2&dchild=1&keywords=lord+of+the+rings+blu+ray&qid=1591304914&sprefix=lord+of+the+rings+blu%2Caps%2C148&sr=8-2"
        marginTop="16px"
      >
        The epic Lord of the Rings Films
      </Body>
      <Body
        as="a"
        href="https://frontendmasters.com/teachers/shirley-wu/"
        marginTop="4px"
      >
        Shirley Wu's great courses on Frontend Masters
      </Body>
      <Body
        as="a"
        href="https://www.imsdb.com/scripts/Lord-of-the-Rings-Fellowship-of-the-Ring,-The.html"
        marginTop="4px"
      >
        imsdb.com
      </Body>
      <Body as="a" href="http://www.ageofthering.com" marginTop="4px">
        Age of the Ring
      </Body>
      <Footer />
    </Container>
  );
}
