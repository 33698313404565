import React from 'react';
import styled from 'styled-components';
import { Body } from './typography';

const Container = styled.footer`
  margin-top: 32px;
  background-color: #64a2c2;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Text = styled(Body)`
  font-size: 16px;
  max-width: none;
  width: 100%;
  text-align: center;
  color: black;

  a {
    color: black;
  }
`;

export default () => (
  <Container>
    <Text>
      Made with{' '}
      <span role="img" aria-label="love">
        ❤️
      </span>{' '}
      by <a href="https://joepurnell.dev">me</a> using React, D3, and hosted on Netlify.
    </Text>
  </Container>
);
